<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <VocabularyTitle :title="$t('sideBar.salehouses')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />
        <!--Search-->
        <div class="d-flex justify-content-between mt-3 w-100">
          <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover columns-resizable">
          <thead>
            <tr>
              <th scope="col">
                <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
                {{ $t('sideBar.salehouses') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="salehousesStatus !== 'success'">
              <td><SpinnerLoader :loading="salehousesStatus" /></td>
            </tr>
            <template v-else>
              <tr v-for="(row, index) in tableData" :key="index">
                <td>
                  <div class="d-flex w-100 flex-fill">
                    <button v-if="canEdit" class="btn-icon" @click="showModalSalehouses('edit', row.id)">
                      <b-icon icon="pencil"></b-icon>
                      {{ $t('table.edit') }}
                    </button>
                    <router-link v-if="canViewUsers" :to="`users?saleshouse_id=${row.id}`" class="btn-icon">
                      <b-icon icon="people"></b-icon>
                      {{ $t('sideBar.users') }}
                    </router-link>
                    <button v-if="canDelete" class="btn-icon" @click="showModalDeleteSalehouses(row)">
                      <b-icon icon="trash"></b-icon>
                      {{ $t('table.delete') }}
                    </button>
                    {{ row.name }}
                  </div>
                </td>
              </tr>
              <tr v-if="salehousesStatus === 'success' && !tableData.length">
                <td>
                  <div class="d-flex w-100 justify-content-center">
                    {{ $t('table.noContent') }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="$checkPermissions('saleshouse.create')" variant="primary" @click="showModalSalehouses('add')">
          {{ $t('table.addNewSalehouses') }}
        </b-button>
      </div>
      <!--FOOTER-->

      <!--MODAL ADD/EDIT SH-->
      <b-modal
        id="add-edit-salehouses"
        ref="add-edit-salehouses"
        :title="modalType === 'add' ? $t('table.addNewSalehouses') : $t('table.editSalehouses')"
        :ok-disabled="!$v.writeTitle.required"
        :ok-title="modalType === 'add' ? $t('table.add') : $t('table.edit')"
        :cancel-title="$t('table.cancel')"
        :busy="isModalBusy"
        @ok.prevent="checkIfValidThenEnter"
      >
        <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
        <form v-else @submit.stop.prevent="checkIfValidThenEnter">
          <input type="submit" value="Submit" class="hidden-submit" />
          <b-form-group :label="$t('table.writeTitle')" label-for="input-sh-name">
            <b-form-input
              id="input-sh-name"
              v-model.trim="$v.writeTitle.$model"
              type="text"
              :placeholder="$t('table.enterTitle')"
              autofocus
              required
            ></b-form-input>
          </b-form-group>
          <div v-if="FEATURES.EQUALS && modalType == 'edit'" class="p-2 border border-danger">
            <!-- <label> Sale house settings </label> -->

            <b-form-group>
              <MultiSelect
                id="year"
                v-model="picked_year"
                :options="years"
                :placeholder="$t('channelModal.pickYear')"
                label="id"
                track-by="id"
                :allow-empty="true"
              ></MultiSelect>
            </b-form-group>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="is_equals_enabled" :aria-describedby="ariaDescribedby" name="some-radios" value="true">{{
                $t('equalsWGRP.equals')
              }}</b-form-radio>
              <b-form-radio v-model="is_equals_enabled" :aria-describedby="ariaDescribedby" name="some-radios" value="false">{{
                $t('equalsWGRP.wGRP')
              }}</b-form-radio>
            </b-form-group>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import SortArrows from '../components/SortArrows';
import MultiSelect from '@/components/MultiSelect.vue';

export default {
  name: 'SaleHouses',
  components: { PaginateWrapper, SpinnerLoader, VocabularyTitle, SearchDropdown, SearchInput, SortArrows, MultiSelect },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
  },

  data() {
    return {
      tableData: [],
      paginationData: '',
      searchElements: '',
      writeTitle: '',
      selectedYear: null,
      filterPage: 1,
      selectedPerPage: '100',
      modalType: 'add',
      isModalLoading: true,
      isModalBusy: false,
      is_equals_enabled: false,
      picked_year: { id: new Date().getFullYear() },
    };
  },
  computed: {
    ...mapGetters({
      salehouses: 'getSalehouses',
      salehousesStatus: 'getSalehousesStatus',
      modalEditSalehouses: 'getModalEditSalehouses',
      years: 'getYear',
      settings: 'getSalehousesSettings',
    }),

    canEdit: function () {
      return this.$checkPermissions('saleshouse.update');
    },
    canDelete: function () {
      return this.$checkPermissions('saleshouse.delete');
    },
    canViewUsers: function () {
      return this.$checkPermissions('user.read_list');
    },
  },
  validations: {
    writeTitle: {
      required,
    },
  },
  watch: {
    salehouses() {
      this.setTableData();
    },
    picked_year() {
      this.getSaleHouseSettings();
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.salehouses') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getSaleHousesInfo();
  },
  destroyed() {
    this.$store.commit('clearSalesHousesList');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_SALEHOUSES', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
      });
      this.updateQuery();
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },
    // get table info
    setTableData() {
      if (this.salehouses) {
        this.tableData = this.salehouses.data;
        this.paginationData = this.salehouses.pagination;
        if (this.filterPage > this.salehouses.pagination.last_page) {
          this.filterPage = this.salehouses.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getSaleHousesInfo() {
      await this.restoreFromQuery();
      this.paramsData();
    },

    // modal add new
    async showModalSalehouses(type, data) {
      this.isModalLoading = true;
      this.writeTitle = '';
      this.modalType = type;
      this.$bvModal.show('add-edit-salehouses');
      if (type === 'edit') {
        await this.$store.dispatch('GET_SALEHOUSES_ID', data);
        this.writeTitle = this.modalEditSalehouses.name;

        if (this.years.length < 1) await this.$store.dispatch('GET_YEAR');

        if (this.FEATURES.EQUALS) {
          await this.getSaleHouseSettings();
        }

        this.writeTitle = this.modalEditSalehouses.name;
      }
      this.isModalLoading = false;
    },

    async addEditSaleshouse() {
      this.isModalBusy = true;
      const formData = {
        id: this.modalType === 'add' ? undefined : this.modalEditSalehouses.id,
        name: this.writeTitle,
      };
      const name = this.writeTitle;
      let put_settings = true;
      if (this.FEATURES.EQUALS) {
        put_settings = await this.putSalehousesSettings();
      }
      await this.$store.dispatch(this.modalType === 'add' ? 'POST_SALEHOUSES' : 'PUT_SALEHOUSES', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalType === 'add' ? this.$i18n.t('alert.addSalehouses') : this.$i18n.t('alert.editSalehouses'),
            text: name,
          });
          if (put_settings) {
            this.$bvModal.hide('add-edit-salehouses');
            this.paramsData();
            this.writeTitle = '';
          }
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isModalBusy = false;
    },
    async getSaleHouseSettings() {
      await this.$store.dispatch('GET_SALEHOUSES_SETTINGS', {
        //    id: 123,
        'filter[saleshouse_id]': this.modalEditSalehouses.id,
        'filter[year_id]': this.picked_year.id,
      });
      this.is_equals_enabled = this.settings[0].is_equals_enabled ? 'true' : 'false';
    },

    async putSalehousesSettings() {
      if (
          this.settings.length === 0
          || this.settings[0].is_equals_enabled == (this.is_equals_enabled == 'true' ? true : false)
      ) return true;
      let formData = {
        id: this.settings[0].id,
        is_equals_enabled: this.is_equals_enabled == 'true' ? true : false,
      };
      let res = false;
      await this.$store.dispatch('PUT_SALEHOUSES_SETTINGS', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalType === 'add' ? this.$i18n.t('alert.addSalehouses') : this.$i18n.t('alert.editSalehouses'),
            text: name,
          });
          res = true;
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      return res;
    },

    // modal delete
    async showModalDeleteSalehouses(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteSalehouses')} "${data.name}"?`, {
          title: this.$i18n.t('table.deleteSalehouses'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data.id;
            const name = data.name;
            this.$store.dispatch('DELETE_SALEHOUSES_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteSalehouses'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => {
                errorsHandler(errors, this.$notify);
              },
            });
          }
        })
        .catch((err) => {});
    },

    checkIfValidThenEnter() {
      if (this.$v.writeTitle.required && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalType === 'add'
              ? `${this.$i18n.t('table.confirmAddSalehouses')} "${this.writeTitle}"?`
              : `${this.$i18n.t('table.confirmEditSalehouses')} "${this.writeTitle}"?`,
            {
              title: this.modalType === 'add' ? this.$i18n.t('table.addSalehouses') : this.$i18n.t('table.editSalehouses'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-add-edit-sh-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditSaleshouse();
          })
          .catch((err) => {});
      }
    },
  },
};
</script>

<style lang="sass"></style>
